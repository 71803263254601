import classNames from 'classnames/bind';
import { graphql } from 'gatsby';
import React from 'react';
import Intro from '../../components/Intro/Intro';
import Section from '../../components/JobPost/Section/Section';
import Seo from '../../components/Seo/Seo';
import * as styles from './MentionsLegales.module.scss';

const c = classNames.bind(styles);

export const query = graphql`
	query($id: String!) {
		page: wpPage(id: { eq: $id }) {
			seo {
				title
				metaDesc
				opengraphTitle
				opengraphDescription
				opengraphSiteName
				opengraphImage {
					uri
				}
				twitterTitle
				twitterDescription
				twitterImage {
					uri
				}
			}
			translations {
				uri
				language {
					locale
				}
			}
			acf: acfPageMentionsLegales {
				header {
					title
					popin_video
					description
					bgtype
					image {
						filesize
						height
						mime_type
						title
						url
						width
					}
					image_mobile {
							filesize
							height
							mime_type
							title
							url
							width
					}
					cover {
						filesize
						height
						mime_type
						title
						url
						width
					}
					video {
						filesize
						mime_type
						subtype
						title
						type
						url
					}
				}
				block_text {
					items {
						text
						title
					}
				}
			}
		}
	}
`;

export default function MentionsLegales({ data }) {
	const { seo, translations, acf } = data.page;
	return (
		<main className={c('wrapper')}>
			<Seo {...{ seo, translations }} />
			<Intro data={acf.header} />
			{acf.block_text.items?.map((item, i) => (
				<Section key={i} {...item} className={c('textBlock')} />
			))}
		</main>
	);
}
